<template>
  <div>
    <div class="header">
      <van-nav-bar :title="$t('hq.title')"> </van-nav-bar>
    </div>
    <div class="maincontent">
      <van-tabs color="#FFFFFF" @click="change" v-model="activeindex">
        <!-- 新闻快讯 -->
        <!-- <van-tab :title="$t('hq.news')">
          <van-row>
            <van-row
              class="publish"
              v-for="item in newslist"
              :key="item.id"
              @click="detail(item.id)"
            >
              <van-col :span="10" style="padding: 10px; height: 100%;">
                <van-image style="width:100%;height:100%" :src="item.image" />
              </van-col>
              <van-col
                :span="14"
                style="padding: 15px; text-align: left; height: 100%;"
              >
                <van-row class="publish-msg">
                  <van-row>
                    <span>{{ item.title }}</span>
                  </van-row>
                </van-row>
                <van-row
                  style="color: rgb(178, 178, 178); margin-top: 8px; font-size: 14px; text-align: right;"
                >
                  <span>{{ item.createtime | dateformat }}</span>
                </van-row>
              </van-col>
            </van-row>
          </van-row>
        </van-tab> -->
        <!-- 市场 -->
        <van-tab :title="$t('hq.market')">
          <!-- <van-tabs color="#FFFFFF">
            <van-tab title="USDT">
            <van-tab> -->
          <van-row class="currency-title">
            <van-col span="8">
              <span>{{ $t("hq.hqname") }}</span>
            </van-col>
            <van-col span="8">
              <span>{{ $t("hq.price") }}</span>
            </van-col>
            <van-col span="8">
              <span>{{ $t("hq.updown") }}</span>
            </van-col>
          </van-row>
          <van-row class="currency-Increase" v-for="item in productlist" :key="item.id" @click="totrade(item.pid, item)">
            <van-col span="3" style="display: flex;align-items: center;justify-content: center;">
              <van-image width="1.5rem" height="1.5rem" :src="item.logo">
              </van-image>
            </van-col>
            <van-col span="7">
              <span>
                {{ item.product_title }}
              </span>
            </van-col>
            <van-col span="7">
              <span style="color:black">
                <b>{{ item.current }}</b>
              </span>
            </van-col>
            <van-col span="7">
              <van-button class="currency-btn" :style="{
                backgroundColor: item.change > 0 ? '#14b95c' : '#f6185b',
              }">
                {{ item.change }}%
              </van-button>
            </van-col>
          </van-row>
          <!-- </van-tab>
          </van-tabs> -->
        </van-tab>
        <!-- 自选 -->
        <van-tab :title="$t('mining')">
          <van-row class="currency-title">
            <van-col span="8"><span>{{ $t("hq.hqname") }}</span></van-col>
            <van-col span="8"><span>{{ $t("hq.price") }}</span></van-col>
            <van-col span="8"><span>{{ $t("hq.updown") }}</span></van-col>
          </van-row>
          <van-row class="currency-Increase" v-for="item in zxlist" :key="item.id" @click="totrade(item.productid, item)">
            <van-col span="3" style="display: flex;align-items: center;justify-content: center;">
              <van-image width="1.5rem" height="1.5rem" :src="item.logo">
              </van-image>
            </van-col>
            <van-col span="7"><span>{{ item.product_title }}</span></van-col>
            <van-col span="7"><span style="color:black"><b>{{ item.current }}</b></span></van-col>
            <van-col span="7"><van-button class="currency-btn" :style="{
              backgroundColor: item.change > 0 ? '#14b95c' : '#f6185b',
            }">
                {{ item.change }}%
              </van-button></van-col>
          </van-row>
          <van-row class="click-add" @click="add">
            <van-icon name="plus" style="font-size: 2rem;" />
            <van-row>
              <span style="color: rgb(198, 197, 197);">{{
                $t("hq.addbtn")
              }}</span>
            </van-row>
          </van-row>
        </van-tab>
        <!-- 创新记录 -->
        <van-tab :title="$t('cxjilu')">
          <van-list v-if="orders.length" v-model="loading" :finished="finished" :finished-text="$t('common.nomore')">
            <van-row @click="onOrderItemClick(item)" class="currency-Increase" v-for="item in orders" :key="item.id">
              <van-col :span="12" style="text-align:left">
                <span>{{ item.code }}</span>
                <br />
                <span style="color:reg(128,128,128)">
                  {{ $t("jilu.buy") }}:
                  <span>{{ item.total_price }}</span>
                </span>
                <br />
                <span style="color:reg(128,128,128)">
                  {{ $t("jilu.biz") }}: {{ item.product_name }}
                </span>
              </van-col>
              <van-col :span="12" class="currency-time">
                <span>
                  <span v-if="item.status === '0'">
                    {{ $t("jiao-yi-zhong") }}
                  </span>
                  <span v-if="item.status === '1'">
                    {{ $t("yi-wan-cheng") }}
                  </span>
                  <br />
                  <span style="color: rgb(128, 128, 128);">{{ $t("jilu.price") }}：{{ item.profit }}
                    >
                  </span>
                  <br />
                  <span style="color: rgb(128, 128, 128);">
                    {{ item.createtime | dateformat }}
                  </span>
                </span>
              </van-col>
            </van-row>
          </van-list>
          <van-empty :description="$t('common.nomore')" v-else />
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newslist: [],
      productlist: [],
      zxlist: [],
      activeindex: 0,
      wk: null,
      orders: [],
      finished: true,
      loading: false,
    };
  },
  created() {
    this.getproduct();
    this.initwk();
  },
  methods: {
    // 创新记录
    async getOrders() {
      const { data } = await this.$http.get("/home/trade/get_orders");
      if (data) {
        if (data.code === 200) {
          this.orders = data.data;
        }
      }
    },
    onOrderItemClick(item){
      // this.$router.push({ path: "/news/OrderDeyail"});
      this.$router.push({
        path: '/news/OrderDeyail',
        query: {
          context: JSON.stringify(item)
        }
      })
    },
    // async getnews() {
    //   const { data } = await this.$http.get("/home/news/newslist");
    //   if (data) {
    //     if (data.code === 200) {
    //       this.newslist = data.data;
    //     }
    //   }
    // },
    async getproduct() {
      const { data } = await this.$http.get("/home/news/productlist");
      if (data) {
        if (data.code === 200) {
          this.productlist = data.data;
        }
      }
    },
    async getzxlist() {
      this.$router.push("/Lockup");

    },
    // 新闻详情
    detail(id) {
      this.$router.push({ path: "/news/detail/" + id });
    },
    // 选项卡事件
    change(id) {
      if (id === 0) {
        // this.getnews()
        this.getproduct();
      }
      if (id === 1) {
        this.getzxlist();
      }
      if (id === 2) {
        this.getOrders();
      }
    },
    // 添加自选
    add() {
      this.$router.push("/trade");
    },
    // 去交易页
    totrade(id, item) {
      this.$router.push("/trade?id=" + id + "&name=" + item.from);
    },
    initwk() {
      this.wk = new WebSocket(this.wsurl + "/wssss:8080");
      this.wk.onmessage = this.wkonmessage;
    },
    wkonmessage(e) {
      let data = JSON.parse(e.data);
      data.map((dataItem, dataIndex) => {
        this.productlist.forEach((item, index) => {
          if (dataItem.product_title === item.product_title) {
            item.change = dataItem.change;
            item.current = dataItem.current;
          }
        });
        this.zxlist.map((itemZX) => {
          if (dataItem.product_title === itemZX.product_title) {
            itemZX.change = dataItem.change;
            itemZX.current = dataItem.current;
          }
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
*,
:after,
:before {
  box-sizing: border-box;
  margin: 0;
}

.header {
  width: 100%;
}

.maincontent {
  padding: 50px 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  background: #fff;

  /deep/.van-tabs__nav--line {
    box-sizing: initial;
    height: 100%;
    padding-bottom: 15px;
  }

  /deep/.van-tab {
    font-size: 1rem;
  }

  /deep/.van-tab--active {
    color: #f0b82d;
  }

  // 新闻快讯
  .publish {
    height: 7.2rem;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    border-bottom: 0.02667rem solid #e5e5e5;
    display: flex;
    justify-content: center;
    align-content: center;

    .publish-msg {
      height: 80%;
      color: #000;
      font-size: 0.8rem;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;

      span {
        line-height: 1.25rem;
      }
    }
  }

  // 自选  // 市场
  .currency-title {
    margin-top: 0.5rem;
    margin-bottom: 0.4rem;
    font-size: 0.8rem;
    color: #b2b2b2;

    span {
      font-size: 0.875rem;
    }
  }

  .currency-Increase {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    font-size: 1.12rem;
    display: flex;
    align-items: center;
    line-height: 1.33333rem;
    border-bottom: 0.02667rem solid #e5e5e5;

    .currency-btn {
      border: none;
      border-radius: 0.26667rem;
      width: 6.4rem;
      height: 2.13333rem;
      color: #fff;
      font-size: 1.15rem;
    }
  }

  // 添加按钮
  .click-add {
    padding-top: 1.5rem;
  }

  .currency-Increase {
    background-color: #fff;
    color: #000;
    padding: 0.8rem 1.33333rem 0.8rem 1.33333rem;
    line-height: 1.6rem;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    border-bottom: 0.02667rem solid #e5e5e5;

    .currency-time {
      text-align: right;
    }
  }
}
</style>
